import CodeExample from "components/docs/code-examples/CodeExample";
import HeaderBar from "components/docs/layout/HeaderBar";
import NavigationScrollTracker from "components/docs/navigation/NavigationScrollTracker";
import NavigationScrollTrackerWithAnchor from "components/docs/navigation/NavigationScrollTrackerWithAnchor";
import SmallWidthSection from "components/docs/sections/SmallWidthSection";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import { WarningCard } from "components/docs/shared-components/NoticeCards";
import SectionDivider from "components/docs/shared-components/SectionDivider";
import { StaticFancyPageContainerWithTOC } from "components/docs/shared-components/StaticFancyPageContainer";
import React from "react";
import styled from "styled-components";
import { palette, spectrum } from "styles/theme";
import DashboardGif from "../../assets/images/docs/guides/magic-link/MergeLinkGIF.gif";
import Image from "react-bootstrap/Image";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { MERGE_DASHBOARD_WEBHOOK_EMITTER_PAGE } from "components/docs/navigation/links";

const CODE_EXAMPLE_FOLDER = "basics/webhooks/webhook-payload";

/**
 * Small width section with padding
 */
const Section = styled(SmallWidthSection).attrs({ className: "mt-9 mb-9" })``;

/**
 * Small width section with smaller padding above
 */
const ShortSection = styled(SmallWidthSection).attrs({ className: "mb-9" })`
  margin-top: 0px;
`;

const Card = styled.div`
  background: #f9fafc;
  border: 1px solid #f1f3f4;
  border-radius: 8px;
  padding: 16px 20px;
  font-size: 15px;
  line-height: 24px;
  color: #2e3135;
  margin: 8px 0 24px 0;

  a {
    color: var(--gray90);
  }

  p {
    margin-bottom: 8px;
  }

  p:last-child {
    margin-bottom: 0px;
  }
`;

export const InfoCard = styled(Card)`
  background: ${spectrum.indigo0};
  border: 1px solid ${spectrum.indigo10};

  a {
    color: inherit;
    font-weight: 600;
  }

  a:hover {
    color: inherit;
    text-decoration: underline;
  }

  .deprecatedh4 {
    color: ${palette.indigo};
  }

  .deprecatedh4 code {
    line-height: 24px;
    font-size: 15px !important;
  }
  &&& code {
    background: ${palette.white};
    padding: 1px 6px;
    border-radius: 4px;
  }
`;

/**
 * Creates our Merge Link Guide
 */
const MergeMagicLink = () => (
  <StaticFancyPageContainerWithTOC
    tableOfContents={[
      {
        text: <>Overview</>,
        anchorSlug: "overview",
      },
      {
        text: <>Generate Magic Link URL</>,
        anchorSlug: "generate-a-magic-link-url",
        linkItems: [
          { text: "Dashboard", anchorSlug: "dashboard-link" },
          { text: "API", anchorSlug: "api" },
        ],
      },
      {
        text: <>Account Token Access</>,
        anchorSlug: "account-token-access",
        linkItems: [
          { text: "Dashboard", anchorSlug: "dashboard-account-token" },
          { text: "Webhook", anchorSlug: "webhook" },
        ],
      },
    ]}
  >
    <DocsHelmet
      title="Magic Link"
      description="Integrate your end users without any frontend code."
    />
    <SmallWidthSection>
      <NavigationScrollTracker>
        <HeaderBar
          title="Magic Link"
          subtitle="Integrate your end users without any frontend code."
        />
      </NavigationScrollTracker>
    </SmallWidthSection>

    <ShortSection>
      <NavigationScrollTrackerWithAnchor headingLevel="h3" title="Overview">
        <p>
          Our <strong>Magic Link</strong> allows you to deliver an in-browser Link experience
          without requiring any frontend code.
        </p>
        <p>The Magic Link experience is sent to your end users via a secure URL.</p>
        <p className="mt-6">Benefits:</p>
        <ul>
          <li>
            <strong>Fast proof of concept of Merge</strong> without making frontend changes to embed
            Link into your app
          </li>
          <li>
            <strong>Your users can authorize integrations</strong> without logging into your app,
            enabling custom user journeys
          </li>
        </ul>
        <StaticImage
          src="../../assets/images/docs/guides/magic-link/magic-link-preview.png"
          alt="Magic Link Preview"
          layout="fixed"
          width={641}
          placeholder="dominantColor"
          quality={100}
        />
      </NavigationScrollTrackerWithAnchor>
    </ShortSection>
    <SectionDivider />
    <Section>
      <NavigationScrollTrackerWithAnchor
        headingLevel="h3"
        title="Generating Magic Link URL"
        anchorSlug="generate-a-magic-link-url"
      >
        <p className="mt-6">A Magic Link instance can be generated in two ways:</p>
        <ul>
          <li>
            <strong>From your Merge dashboard</strong> <em>(basic)</em>
          </li>
          <li>
            <strong>Programmatically via API</strong> <em>(advanced)</em>
          </li>
        </ul>
      </NavigationScrollTrackerWithAnchor>
      <NavigationScrollTrackerWithAnchor
        headingLevel="h4"
        title="Dashboard"
        anchorSlug="dashboard-link"
      >
        <Image fluid={true} src={DashboardGif} alt="Merge Link demo in dashboard" />

        <p className="mt-6">
          You can initialize a Magic Link from the Merge Dashboard in{" "}
          <strong>four easy steps</strong>.
        </p>
        <ol>
          <li>
            Navigate to <strong>Linked Accounts</strong> in the left navigation
          </li>
          <li>
            Under Production Linked Accounts, click the button to <strong>Send Link via URL</strong>
          </li>
          <li>
            Enter your end user's information and the integration categories you want to make
            available to them
          </li>
          <li>
            Click <strong>Generate URL</strong>
          </li>
        </ol>
        <p>
          That's it! You will be presented with a unique URL that you can send to your customer.
        </p>
        <p>
          <em>The URL will expire within 7 days</em>
        </p>
      </NavigationScrollTrackerWithAnchor>
      <NavigationScrollTrackerWithAnchor headingLevel="h4" title="API" anchorSlug="api">
        <p>Merge also offers the ability to programmatically generate Magic Link URLs via API.</p>
        <p>
          To do this, make a POST request to the <Link to="/hris/link-token/">/link-token</Link>{" "}
          endpoint.
        </p>
        <p>
          In the body parameters of your POST request, set the{" "}
          <code>should_create_magic_link_url</code> parameter to <code>true</code>.
        </p>
        <InfoCard style={{ pointerEvents: "auto" }}>
          <p>
            <code>link_expiry_mins</code>
          </p>
          <p>
            In your POST request, we recommend setting the <code>link_expiry_mins</code> body
            parameter to the maximum of <code>10080</code> so that the Magic Link URL expires after
            7 days.
          </p>
          <p>
            If this parameter is not explicitly set, the generated Magic Link URL will expire 30
            minutes after creation.
          </p>
        </InfoCard>
      </NavigationScrollTrackerWithAnchor>
    </Section>
    <SectionDivider />
    <Section>
      <NavigationScrollTrackerWithAnchor
        headingLevel="h3"
        title="Accessing Account Tokens for Linked Accounts Created with Magic Link"
        anchorSlug="account-token-access"
      >
        <p>
          Once your customer has successfully integrated their third-pary platform through Magic
          Link, you will need the new Linked Account's <strong>account token</strong> to make API
          requests to Merge on behalf of that Linked Account.
        </p>

        <p className="mt-6">
          You are able to access the account tokens of Linked Accounts created through Magic Link in
          one of two ways:
        </p>
        <ul>
          <li>
            <strong>From your Merge Dashboard</strong> <em>(basic)</em>
          </li>
          <li>
            <strong>Programmatically via Merge Webhook</strong> <em>(advanced)</em>
          </li>
        </ul>
      </NavigationScrollTrackerWithAnchor>
      <NavigationScrollTrackerWithAnchor
        headingLevel="h4"
        title="Dashboard"
        anchorSlug="dashboard-account-token"
      >
        <p className="mt-6">
          You can access the account token of a Product Linked Account directly in the Merge
          Dashboard with the following steps.
        </p>
        <ol>
          <li>
            Navigate to <strong>Linked Accounts</strong> in the left navigation
          </li>
          <li>Under "Production Linked Accounts", select a Linked Account to go to its overview</li>
          <li>
            In the overview screen, the account token is in the <strong>bottom right</strong>
          </li>
        </ol>
        <WarningCard>
          <p>
            Account tokens are only visible to users with the <strong>Admin</strong> role.
          </p>
        </WarningCard>
      </NavigationScrollTrackerWithAnchor>
      <NavigationScrollTrackerWithAnchor headingLevel="h4" title="Webhook" anchorSlug="webhook">
        <p>
          You can also receive the account token of a new Production Linked Account as soon as a
          connection is created by setting up a <strong>Linked Account linked</strong> webhook in
          your{" "}
          <a href={MERGE_DASHBOARD_WEBHOOK_EMITTER_PAGE} target="_blank" rel="noreferrer">
            Merge Dashboard
          </a>
          .
        </p>
        <p>
          This webhook will notify you in real time when a Production Linked Account is created,
          indicated by <code>is_relink</code> returning <code>false</code> and the payload will
          contain the new Linked Account's account token.
        </p>

        <CodeExample
          folder="basics/webhooks/webhook-payload"
          codeBlockName="Linked Account linked webhook payload example"
          fileBaseName="account-linked"
          colorScheme="light"
          hasLineNumbers={false}
          style={{ marginTop: "48px" }}
          hideCopyButtonWithHeader
        />

        <aside>
          To learn more about Merge Webhooks, view our <Link to="/basics/webhooks">Webhooks </Link>
          guide.
        </aside>
      </NavigationScrollTrackerWithAnchor>
    </Section>
  </StaticFancyPageContainerWithTOC>
);

export default MergeMagicLink;
